<template>
  <p v-if="hasError" class="error">{{ message }}</p>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  /**
   * Components Name.
   *
   * @type {String}
   */
  name: "form-FormError",

  /**
   * Components properties.
   *
   * @type {Object}
   */
  props: {
    /**
     * Error key to pull from error messages.
     * @type String
     */
    errorKey: {
      type: String,
      required: true
    }
  },

  /**
   * Components computed properties.
   *
   * @type {Object}
   */
  computed: {
    /**
     * Determine if we have error message in the errors list for the given error key.
     * @return {boolean}
     */
    hasError() {
      return Object.keys(this.getErrors).length > 0 && this.getErrors[this.errorKey]
    },

    /**
     * Get error message from the errors list.
     *
     * @return {String}
     */
    message() {
      return this.getErrors[this.errorKey]
    },

    ...mapGetters('errors', ['getErrors'])
  }
}
</script>

<style scoped>

</style>